import { DualAxes } from "@ant-design/charts";
import React, { useEffect, useState } from "react";

const CombinationChartSingleYAxisComponent = (props) => {
  const [currentYearData, setCurrentYearData] = useState([]);
  const [averageBasketValueData, setAverageBasketValueData] = useState([]);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  
  useEffect(() => {
    if (props.combinationChartSingleYAxisdata) {
      const data = props.combinationChartSingleYAxisdata;
      const currentYearData = getCurrentYearData(data);
      const averageBasketValueData = getAverageBasketValueData(data);
      
      setCurrentYearData(currentYearData);
      setAverageBasketValueData(averageBasketValueData);
      
      let height;
      let width;
      const sider = JSON.parse(localStorage.getItem("lowerSubMenu"));
      const fullScreenValue = props.fullScreenValue;
      
      if (fullScreenValue === true && sider !== null) {
        height = 300;
        width = 900;
      } else if (fullScreenValue === false && sider === null) {
        height = 250;
        width = 560;
      } else if (fullScreenValue === false && sider !== null) {
        height = 250;
        width = 480;
      } else if (fullScreenValue === true && sider === null) {
        height = 300;
        width = 1100;
      }
      
      setHeight(height);
      setWidth(width);
    }
  }, [props.combinationChartSingleYAxisdata, props.fullScreenValue]);
  
  const getCurrentYearData = (data) => {
    const currentYearData = data[0]?.slice(1); // Extracting the date labels
    const currentYearValues = data[1].slice(1); // Extracting the current year values
    // const averageValues = data[2].slice(1); // Extracting the average values
    const previousYearValues = data[3].slice(1); // Extracting the previous year values
    
    const result = [];
    
    // Iterating over the arrays to create objects for current, average, and previous year values
    for (let i = 0; i < currentYearData.length; i++) {
      const date = currentYearData[i];
      const currentValue = parseFloat(currentYearValues[i]);
    //   const averageValue = parseFloat(averageValues[i]);
      const previousValue = parseFloat(previousYearValues[i]);
      
      // Pushing current year data
      result.push({ date, value: currentValue, type: 'current' });
      
      // Pushing average data
    //   result.push({ date, value: averageValue, type: 'average' });
      
      // Pushing previous year data
      result.push({ date, value: previousValue, type: 'previous' });
    }
    
    return result;
  }
  
  const getAverageBasketValueData = (data) => {
    const headers = data[0].slice(1); // Exclude 'x'
    const values = data[2].slice(1); // Exclude 'avgbasketvalueline'
    const result = [];
    
    headers.forEach((header, index) => {
      result.push({
        date: header,
        average: values[index]
      });
    });
    
    return result;
  };

  const config = {
    xField: 'date',
    legend: {
      color: {
        position: 'bottom',
        layout: { justifyContent: 'center' },
      },
    },
    scale: { color: { range: ['#1783FF', '#5AD8A6', '#5D7092', '#F6BD16'] } },
    children: [
      {
        data: currentYearData,
        type: 'interval',
        yField: 'value',
        colorField: 'type',
        group: true,
        style: { maxWidth: 50 },
        interaction: { elementHighlightByColor: { background: true } },
      },
      {
        data: averageBasketValueData,
        type: 'line',
        yField: 'average',
        style: { lineWidth: 1 },
        axis: { y: { position: 'right' } },
        interaction: {
          tooltip: {
            crosshairs: false,
            marker: false,
          },
        },
      },
    ],
    axis:{
        x:{
         label:false,
        },
        y:{labelFormatter:  (val) => {
           if (val / 1000000000 >= 1) {
             return `${(val / 1000000000).toFixed(1)} B`;
           } else if (val / 1000000 >= 1) {
             return `${(val / 1000000).toFixed(1)} M`;
           } else if (val / 1000 >= 1) {
             return `${(val / 1000).toFixed(1)} K`;
           } else {
             return val;
           }
       },
        }
       },
    legend: {
        color: {
          itemMarker: (v) => {
            if (v === 'current' || v === 'previous') return 'rect'
            return 'smooth'
          }
        }
      },
  };

  return (
    <DualAxes width={width} height={height} {...config} />
  )
}

export default CombinationChartSingleYAxisComponent;
