import { useEffect } from 'react';
import React from 'react'
import { Line } from '@ant-design/plots';

const LineChart = (props) => {
  const sider = JSON.parse(localStorage.getItem("lowerSubMenu"))
  const fullScreenValue =props.fullScreenValue;
  if (props.lineChartdata) {
    const headerRow = props.lineChartdata[0]?.slice(1)?.map(day => day.trim()) || [];
    const dataRows = props.lineChartdata.slice(1).map((row, index) => {
      const type = index === 0 ? 'current' : 'previous';
      return row.slice(1).map((value, i) => ({
        date: headerRow[i],
        value: parseFloat(value),
        type
      }));
    }).flat();
    


    const config = {
      data: dataRows, // Use dataRows instead of static data
      xField: "date", // Assuming date is the field in dataRows representing x-axis
      yField: "value",
      colorField: 'type',
      legend: {
        color: {
          position: 'top',
          layout: { justifyContent: 'center' },
          
        },
      },
      axis:{
        y:{labelFormatter:  (val) => {
           if (val / 1000000000 >= 1) {
             return `${(val / 1000000000).toFixed(1)} B`;
           } else if (val / 1000000 >= 1) {
             return `${(val / 1000000).toFixed(1)} M`;
           } else if (val / 1000 >= 1) {
             return `${(val / 1000).toFixed(1)} K`;
           } else {
             return val;
           }
       },
        }
       },
      point: {
        shapeField: 'diamond',
        sizeField: 4,
      },
      scale: { color: { range: ['#30BF78', '#F4664A'] } },
      
    };
    let width;
    let height;
    
    if (fullScreenValue === true && sider !== null) {
      height = 300;
      width = 900;
    } else if (fullScreenValue === false && sider === null) {
      height = 250;
      width = 550;
    } else if (fullScreenValue === false && sider !== null) {
      height = 250;
      width = 450;
    } else if (fullScreenValue === true && sider === null) {
      height = 300;
      width = 1100;
    }
    
    return (
      <>
        <Line {...config} width={width} height={height} />
        {/* <div style={{paddingTop:'2%',right:'10px'}} id={`chart${uniqueIndex}`} /> */}
      </>
    );
  }

  return null; // Return null if lineChartdata is not available
}

export default LineChart;
