import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import "./styles/app.css"
import { ConfigProvider } from "antd";

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider 
    theme={{
      token:{
       colorPrimary:"#A4CD38",
       colorPrimaryBgHover:"#A4CD38"
      },
      components:{
        Button:{
          colorPrimary:"#2f3856",
          colorPrimaryHover:"#2f3856"
        },
        Table:{
          cellPaddingBlockSM:5
        },
        Select:{
          borderRadius:10
        }
      }
    }}>
    <App />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// reportWebVitals();
