import React, { useState } from "react";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Card, Tooltip, Result, Modal,Popover } from "antd";
import { upsertTabData } from "../../services/generic";
import { useGlobalContext,useWindowContext } from "../../lib/storage";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  ...draggableStyle,
});
const getListStyle = (isDraggingOver) => ({});

function QuoteApp(props) {
  let identiFiersData = props.identiFiers;
  let laneDatas = props.laneData;
  let laneJsonObj = {};

  let lanePureData = [];
  for (let key in identiFiersData) {
    for (let keys in laneDatas) {
      if (laneDatas[keys] === identiFiersData[key]["key"]) {
        laneJsonObj[identiFiersData[key]["key"]] = identiFiersData[key]["value"];
      }
    }
  }

  props.cardData.filter((data) => {
    for (let key in data) {
      lanePureData.push(data[key]);
    }

    return 0;
  });

  const [stateTemp, setStateTemp] = useState(lanePureData);
  const [isUpsertRecordSuccess, setIsUpsertRecordSuccess] = useState(false);
  const [upsertSuccessFailedStatus, setUpsertSuccessFailedStatus] = useState("");
  const [upsertSuccessFailedMessage, setUpsertSuccessFailedMessage] = useState("");
  const { globalStore, setGlobalStore } = useGlobalContext();
  const { windowStore } = useWindowContext();
  const windowDefinition = { ...windowStore.windowDefinition };
  const { userData } = globalStore;
  const Themes = userData.CW360_V2_UI;
  const move = (source, destination, droppableSource, droppableDestination, headerTabData, statusId, laneData) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const recordId = removed["recordId"];

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    for (let j in removed) {
      if (j.includes("_iden")) {
        delete removed[j];
      }
      if (j.includes("key")) {
        delete removed[j];
      }
      if (j.includes("recordId")) {
        delete removed[j];
      }
    }

    removed[statusId] = laneData[droppableDestination.droppableId];

    const stringifiedFields = JSON.stringify(removed);
    const updatedStrings = stringifiedFields.replace(/\\"/g, '\\"');
    const stringRequest = JSON.stringify(updatedStrings);

    const headerTabId = headerTabData.ad_tab_id;

    upsertTabData(windowDefinition.ad_window_id,headerTabId, recordId, stringRequest).then((upsertResponse) => {
      if (upsertResponse.data.data.upsertTab.status === "200") {
        setUpsertSuccessFailedStatus("success");
        setUpsertSuccessFailedMessage(upsertResponse.data.data.upsertTab.message);
        setIsUpsertRecordSuccess(true);
      } else {
        setUpsertSuccessFailedMessage(upsertResponse.data.data.upsertTab.message);
        setUpsertSuccessFailedStatus("error");
      }
    });

    return result;
  };

  const handleCancelSuccessModal = () => {
    setIsUpsertRecordSuccess(false);
  };

  function onDragEnd(result) {
    const { source, destination } = result;

    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(stateTemp[sInd], source.index, destination.index);
      const newState = [...stateTemp];
      newState[sInd] = items;
      setStateTemp(newState);
    } else {
      const result = move(stateTemp[sInd], stateTemp[dInd], source, destination, props.hederTabData, props.statusID, props.laneData);
      const newState = props.cardData;
      for (let key in newState[sInd]) {
        newState[sInd][key] = result[sInd];
      }

      for (let key in newState[dInd]) {
        newState[dInd][key] = result[dInd];
      }
      setStateTemp(newState);
    }
  }
  return (
    <Card
      bordered={true}
      bodyStyle={{ padding: "0px" }}
      style={{ overflow: "scroll", height: "79vh", backgroundColor: "#f5f5f5", border: "0px solid rgb(245, 245, 245)" }}
    >
      <div style={{ marginTop: "-5px" }}>
        <div style={{ display: "inline-flex" }}>
          
        </div>
      </div>
      <div>
        <Modal width="26%" style={{ top: "7rem" }} visible={isUpsertRecordSuccess} footer={[]} onCancel={handleCancelSuccessModal}>
          <Result
            status={upsertSuccessFailedStatus}
            title={upsertSuccessFailedStatus === "success" ? upsertSuccessFailedMessage : ""}
            subTitle={upsertSuccessFailedStatus === "error" ? upsertSuccessFailedMessage : ""}
          />
        </Modal>
      </div>
    </Card>
  );
}

export default QuoteApp;
