import React, { useState, useEffect } from "react";
import { Form, Input, Button, message, Col, Spin, Row } from "antd";
import { UserOutlined, LockOutlined, LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useGlobalContext } from "../../lib/storage";
import { getUser, get360MenuList, getUserPreferencesData, updateLocalToken, getGlobalParameters } from "../../services/generic";
import { updateCustomLocalToken } from "../../services/custom";
import loginLogo from "../../assets/images/NewLogoCW.svg";
// import "antd/dist/antd.css";
import "../../styles/app.css";
import "./login.css";
import ThemeJson from "../../constants/UIServer.json";
import Axios from "axios";
// import { tamURL } from "../../constants/serverConfig";
import { getOAuthHeaders } from "../../constants/oAuthValidation";

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const tamURL = process.env.REACT_APP_tamURL;
  const { globalStore, setGlobalStore } = useGlobalContext();
  const history = useHistory();
  // let token = JSON.parse(localStorage.getItem("authTokens"));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { authTokensFlag } = globalStore;
        if (authTokensFlag) {
          let userApps = JSON.parse(localStorage.getItem("userApps"));
          localStorage.setItem("sideMenuData", JSON.stringify(userApps));
          setGlobalStore({ sideMenuData: userApps });
          history.push("/");
        } else {
          setLoading(true);
    
          setGlobalStore({ authTokens: null, userData: null, sideMenuData: null, userPreferences: null, globalPreferences: null, windowTabs: [] });
          let userApps = JSON.parse(localStorage.getItem("userApps"));
          localStorage.setItem("authTokensFlag", true);
       
          const userDataResponse = JSON.parse(localStorage.getItem("userData"));
          const userPreferencesResponse = JSON.parse(localStorage.getItem("userPreferences"));
          const globalParametersResponse = JSON.parse(localStorage.getItem("globalParameters"))
    
          let appId = localStorage.getItem("appId");
          localStorage.setItem("sideMenuData", JSON.stringify(userApps));
          let menuDataResponse = JSON.parse(localStorage.getItem(appId));
    
          if (menuDataResponse?.length > 0) {
            menuDataResponse[0]?.children.sort((a, b) => {
              const x = a.seqno !== null ? parseInt(a.seqno) : a.seqno;
              const y = b.seqno !== null ? parseInt(b.seqno) : b.seqno;
              return (x != null ? x : Infinity) - (y != null ? y : Infinity);
            });
            localStorage.setItem("subMenuData", JSON.stringify(menuDataResponse[0]?.children));
          } else {
            localStorage.setItem("subMenuData", JSON.stringify([]));
          };
    
          setGlobalStore({ authTokensFlag: true, userData: userDataResponse, sideMenuData: userApps, userPreferences: userPreferencesResponse, globalPreferences: globalParametersResponse });
        
          setLoading(false);
          const refState = props.location.state;
    
          if (refState !== undefined && refState.referer.pathname !== "/:appName") {
            history.push(props.location.state.referer.pathname);
          } else {
            history.push("/");
          };
        };
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error if needed
      }
    };
  
    fetchData();
  }, []);
  

  return (
    <div className="mainDiv col-ms-12">
      <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "30px", color: "#0C173A", opacity: 0.9 }} />} spinning={loading}>
        {/* <div className="innerBlock col-ms-10">
        <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "30px", color: "#0C173A", opacity: 0.9 }} />} spinning={loading.status} tip={loading.message}>
          <Row>
            <Col span={2}></Col>
            <Col span={16} offset={4} >
              <img className="cwLoginLogo" src={loginLogo}></img></Col>
            <Col span={2}></Col>
          </Row>
          <Form onFinish={onLogin} onFinishFailed={onLoginInvalid}>
            <Form.Item name="username">
              <Col span={22}>
                <Input className = "usernameInput"style={{ backgroundColor: "#D8DADE", fontSize: "1rem", borderRadius: 5, borderColor: "#D8DDE6", marginTop: "5%", marginLeft: "1.2em", height: "3.7em" }} placeholder="Username" />
              </Col>
            </Form.Item>
            <Form.Item name="password">
              <Col span={22}>
                <Input.Password visibilityToggle = {false} className="passwordColorBackground" style={{ backgroundColor: "#D8DADE", fontSize: "1rem", borderRadius: 5, borderColor: "#D8DDE6", marginTop: "7.5%", marginLeft: "1.2em", height: "3.7em" }} placeholder="Password" />
              </Col>
            </Form.Item>
            <Form.Item><Col span={22}>
              <Button style={{ backgroundColor: "#A0C838", fontWeight: 600, borderRadius: 5, borderColor: "#D8DDE6", marginTop: "7.5%", letterSpacing: "0.32px", marginLeft: "1.2em", width: "100%", height: "3.7em" }}
                type="primary" htmlType="submit">Login</Button></Col></Form.Item>

            <div className="resetPasswordLog">
              <Row> 
                <Col><h4 style={{ marginLeft: "1.2em" }}>Forgot your password ?</h4></Col>
                <Col><Link style={{ marginLeft: "0.80em" }} to="./NewPassword">Reset Password </Link></Col>
              </Row>
            </div>
          </Form>
        </Spin>
      </div>

      <div className="copyright col-ms-10">
        <Col>
          <h5 style={{ fontSize: "0.85em", textAlign: "center", color: "#FAFAFAE0", fontWeight: 400 }}>This site uses cookies to manage user authentication, analytics, and to provide an improved digital experience. You can learn more about the cookies we use as well as how you can change your cookie settings by clicking here. By continuing to use this site without changing your settings, you are agreeing to our use of cookies. Review CW Suite's Privacy Statement to learn more.</h5>
        </Col>
        </div>

      <div className="col-ms-10">
        <Col>
          <h5 style={{ fontSize: "0.85em", marginTop: "8%", textAlign: "center", color: "#FAFAFAE0", fontWeight: 400 }}>Copyright 2020 CW Suite | All Rights Reserved</h5>
        </Col>
        </div> */}

      </Spin>
    </div>
  );
};

export default Login;