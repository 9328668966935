/* eslint-disable */

import React from 'react';
import { Column } from '@ant-design/plots';



const BarChart=(props)=> {
    const sider = JSON.parse(localStorage.getItem("lowerSubMenu"));
    const fullScreenValue =props.fullScreenValue;

    if (props.barChartdata) {
      const headerRow = props.barChartdata[0]?.slice(1)?.map(day => day?.trim()) || [];
      const dataRows = props.barChartdata.slice(1).map((row, index) => {
        const type = index === 0 ? 'current' : 'previous';
        return row.slice(1).map((value, i) => ({
          date: headerRow[i],
          value: parseFloat(value),
          type
        }));
      }).flat();

      const config = {
        xField: 'date',
        legend: {
          color: {
            position: 'top',
            layout: { justifyContent: 'center' },
            
          },
        },
        axis:{
         x:{
          label:false
         },
         y:{labelFormatter:  (val) => {
            if (val / 1000000000 >= 1) {
              return `${(val / 1000000000).toFixed(1)} B`;
            } else if (val / 1000000 >= 1) {
              return `${(val / 1000000).toFixed(1)} M`;
            } else if (val / 1000 >= 1) {
              return `${(val / 1000).toFixed(1)} K`;
            } else {
              return val;
            }
        },
         }
        },
        
        // scrollbar: {
        //   x: {
        //     ratio: 0.10,
        //   },
        // },
        children: [
          {
            data: dataRows,
            yField: 'value',
            colorField: 'type',
            type:"interval",
            group: true,
            style: { maxWidth: 50 },
            interaction: { elementHighlightByColor: { background: true } },
          },
        ],
        scale: { color: { range: ['#BE408C', '#FACDAA'] } },
      };
      let width;
    let height;
    
    if (fullScreenValue === true && sider !== null) {
      height = 300;
      width = 900;
    } else if (fullScreenValue === false && sider === null) {
      height = 250;
      width = 550;
    } else if (fullScreenValue === false && sider !== null) {
      height = 250;
      width = 450;
    } else if (fullScreenValue === true && sider === null) {
      height = 300;
      width = 1100;
    }  
    return (
      <>
        <Column {...config} width={width} height={height}/>
        {/* <div style={{paddingTop:'2%',right:15}} id={`chart${uniqueIndex}`} ref={chartRef}/> */}
      </>
    );
    }
}

export default BarChart;

